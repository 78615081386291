import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ValueShareService {
  constructor(private http: HttpClient) {}

  /**
   * Get company assets
   */
  getCompanyAssets(): Observable<any> {
    return this.http.get(
      environment.compapiURL +
        "company-service/v1/api/v1/manage/asset/company/get"
    );
  }

  /**
   * Update company assets
   * @param  assetsData: any
   */
  updateCompanyAssets(assetsData: any): Observable<any> {
    return this.http.post(
      environment.compapiURL +
        "company-service/v1/api/v1/manage/asset/company/update",
      assetsData
    );
  }
}
